export const AUTH = 'AUTH'
export const GET = 'GET'
export const GET_POSTS_OTHERS = 'GET_POSTS_OTHERS'
export const CREATE_POST = 'CREATE_POST'
export const GET_POST = 'GET_POST'
export const UPDATE_POST = 'UPDATE_POST'
export const DELETE = 'DELETE'
export const GET_POST_BY_ID = 'GET_POST_BY_ID'
export const UPDATE = ' UPDATE'
export const LOGOUT = ' LOGOUT'
export const DELETE_USER = ' DELETE_USER'
export const START_LOADING = ' START_LOADING'
export const END_LOADING = ' END_LOADING'
export const LIKE = 'LIKE'
export const COMMENT = ' COMMENT'