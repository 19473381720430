import { v4 as uuidv4 } from "uuid";
import Img from '../images/profile.png'
// import enyinnayaImg from '../images/team/Enyinnaya Okwuonu Chairman.jpg'
import Img2 from '../images/team/akin.jpg'
import akpabioImg from '../images/team/Akpabio.jpg'
import boseImg from '../images/team/Bose Olasupo.jpeg'
import oliverImg from '../images/team/oliver.jpg'
import akinyeleImg from '../images/team/akinyele.jpg'
import Img25 from '../images/team/fal.jpg'
import ImgC18 from '../images/team/victor.jpg'
import ImgC6 from '../images/team/john.jpg'
import ImgB11 from '../images/team/ola.jpg'
import ImgB8 from '../images/team/moses.png'
import ImgB31 from '../images/team/juliet.png'
import ImgB32 from '../images/team/frank.jpg'
import ImgB17 from '../images/team/tosin.jpg'
import tonyeImg from '../images/team/tonye.png'
import kenImg from '../images/team/ken.jpg'
import obinnaImg from '../images/team/obinna.jpg'
import benImg from '../images/team/ben.jpg'
import helenImg from '../images/team/helen.jpg'
import oladeleImg from '../images/team/oladele.jpg'
import osayiImg from '../images/team/osayi.png'
import phyllisImg from '../images/team/phyllis.jpeg'
import tonyImg from '../images/team/tony.jpg'
import idiongImg from '../images/team/idiong.jpg'
import joyImg from '../images/team/joy.jpeg'
import elebuteImg from '../images/team/elebute.jpg'
import olaoyeImg from '../images/team/olaoye.jpg'
import amusanImg from '../images/team/ola.jpeg'
import tundeImg from '../images/team/tunde.jpg'
import samuelImg from '../images/team/samuel.jpg'
import oladitiImg from '../images/team/oladiti.jpg'
import peterImg from '../images/team/peter.jpg'
import clemsImg from '../images/team/clems.png'
import folahanImg from '../images/team/folahan.jpg'
import femiImg from '../images/team/femi.jpg'
import ephriamImg from '../images/team/ephriam.jpg'
import joshuaImg from '../images/team/joshua.JPG'
import babatopeImg from '../images/team/babatope.jpg'
import patrickImg from '../images/team/patrick.jpg'
import davidImg from '../images/team/david.jpeg'
import ericImg from '../images/team/eric.jpg'

export const cwc = [
  {
    id: uuidv4(),
    img: Img,
    name: "APOSTLE Enyinnaya Emeka Okwuonu",
    title: "CHAIRMAN ",
    position: "Lead Pastor",
    church: "New Life Christian Church",
    address: "97c, Marine Road, Apapa GRA",
    tel: "08023355366, 07061000181",
    dOb: "30th March",
    email: "enyinnaya56@yahoo.com",
    chapter: "Apapa",
    province: "Apapa",
    bio: "My name is Apostle Dr Enyinnaya Emeka Okwuonu, married to Pastor Mrs Treasure Uloma Okwuonu, have three children and a grandson.",
    header: "CWC"
  },
  {
    id: uuidv4(),
    img: Img2,
    name: "PASTOR LEKE AKINOLA",
    title: "DEPUTY CHAIRMAN ",
    position: "General overseer",
    church: "Upper Room Baptist Church",
    address: "20, Davies Street, Off Demurin Street, Ketu Lagos",
    tel: "08035652137, 08057845480",
    dOb: "2nd February",
    email: "lekeakinola@yahoo.co.uk",
    chapter: "Ketu Central",
    province: "Kosofe",
    header: "CWC"
  },
  {
    id: uuidv4(),
    img: akinyeleImg,
    name: "APOSTLE Mehmood Akinyele Akindejoye",
    title: "SECRETARY ",
    positon: "Senior Pastor",
    church: "Guiding Light Assembly Epe Worship Centre ",
    address:
      "Block 2 Plot 5 Atowa Adegoke Street, Agric Estate, Epe ",
    tel: "08033212772, 09055514094",
    dOb: "9th March",
    email: "dejoye@gmail.com Pastorakinz2@yahoo.com",
    chapter: "Papa-Epe",
    province: "EPE",
    associatePastorTel: "08034202048",
    bio: "Apostle Akin Akindejoye formerly a Muslim who got converted to Christianity. Trained in accounting, Theology and English, he is called to be a teacher and preacher of the word of God which he does with power, passion, insight and addiction for heaven. Pastor Akin, as he is fondly called, previously lectured at Community Bible Institute CBI and Light Bible Institute LBI, both in Lagos Nigeria. Pastor Akin is an author of several books including Subsidy, Journey of a Dream, Journey to Berachah, One Talent Servant, among others. He is the Senior Pastor of GLA Global City Church Epe, Lagos. He is also the Founder and President of AMA World Outreach. The ministry is the sponsor of the annual Youth Conference, Pentecostal Groove as well as the Adventure Seminar Series and Talent Warehouse.. He is a member of the Nigerian Coalition of Apostolic Leaders NCAL as well as the International Coalition Of Apostolic Leaders ICAL. He has held several leadership positions in the Pentecostal Fellowship of Nigeria. Be was the pioneer Secretary of Lekki Region, pioneer province Chairman of Epe-Lekki Province and State Deputy Secretary. He currently serves as the State Secretary, Pentecostal Fellowship of Nigeria PFN, Lagos State. He is happily married and blessed with children. Phone : +234-8033212772 E-mail : dejoye@gmail.com . ",
    header: "CWC"
  },
  {
    id: uuidv4(),
    img: phyllisImg,
    name: "REV (DR) Phyllis Peace Goodey",
    title: "TREASURER ",
    position: "Lead Pastor",
    church: "Christ Believers Healing Ministry",
    address: "",
    headOffice:
      "Truth And Holiness Parish Oluwakemi Close(Street by Access Bank) off Addo Road, Ajah.",
    tel: "08057797482, 08023187035",
    dOb: "27th November",
    email: "goodeypeace@yahoo.com",
    chapter: "",
    province: "Eti-Osa",
    associatePastor: "Pastor (Barrister) Udom Udom",
    associatePastorTel: "08035230642",
    associatePastorChapter: "Ajah",
    associatePastorProvince: "Eti/Osa",
    bio: "My name is Dr Peace Goodey, I generally oversee to Parishes of Christ Believers Healing Ministry and I am also the founder of the Alabaster Jar Foundation For Teenage Mums. I have a degree in Optometry and a Post Graduate Diploma in Leadership and Theology from",
    header: "CWC"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "PASTOR BAYO OYEYEMI",
    title: "Financial Secretary ",
    church: "Deeper Christian Life Ministries",
    address: "",
    headOffice: "",
    tel: "08023213789, +234 815 090 1500",
    dOb: "",
    email: "",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "CWC"
  }
];

// The Directors
export const directors = [
  {
    id: uuidv4(),
    img: ImgB31,
    name: "Rev. Juliet Binitie",
    title: "DEPUTY SECRETARY",
    position: "PRESIDENT/FOUNDER",
    church: "Precious Jewel Ministries International",
    address: "",
    headOffice: "",
    tel: "+234 805 799 0930",
    dOb: "June 4",
    email: "revjulietbinitie@gmail.com",
    chapter: "",
    province: "I am in Mainland Province",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "Rev. Juliet Binitie is a Social Entrepreneur, a Socio- Political commentator and consultant to Media houses like Channels and TVC, a Reformer with years of experience. Also, an Accredited Mediator in Nigeria. She currently serves as the Director for Programmes at the Institute for National Transformation. Also, as an Executive Director for Hiram Design Concept LTD. She is a CEO of newly founded Media organization known as Matters Arising Venture. She is an ordained Minister with a passion for National Transformation. She is a reformer and has a call to raise leadership for the Continent, including Women leadership. She serves in the Pentecostal Fellowship of Nigeria Lagos Chapter as the Asst. Secretary of Lagos State. She started in PFN almost 2 decade starting at the chapter level as Deputy treasurer. She served as Mainland Province secretary for 8 years. Juliet Binitie believes that the Transformation of Nigeria is possible and she has dedicated her life to this cause. She is a life Coach, an Interior designer and a facilitator on Nation Building principles and Social issues. She is a pioneer graduate of the Institute for National Transformation Nigeria and served as the Alumni Vice President for 5 years. She is a recent graduate of the Pioneer class of the school of Politics, Policy and Governance in Nigeria. She has authored books including 2 one year Devotionals. She is a Coach, an author, Facilitator and Mentor to many. Married for 27 years and a mother of two adults.",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: folahanImg,
    name: "REV. OLUSANYA FOLAHAN OLUMUYIWA ",
    title: "State Deputy Treasurer ",
    church: "The Living Stone Builders Ministries",
    position: "General Overseer",
    address:
      "56/58 Lambo Lasunwon Road, opposite Lagos State University of Science and Technology First Gate,Ikorodu-Sagamu Road,Ikorodu.",
    headOffice: "",
    tel: "08023375174",
    dOb: " 6th February ",
    email: "folahanolusanya@yahoo.com ",
    chapter: "Jubilee",
    province: "Itamaga Province. ",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "Born 6th February 1959; from Odogbolu, Ogun State. Graduated from University of Ibadan with Bachelors and Masters degrees in Agricultural Economics and MBA in General Management from Olabisi Onabanjo University, Ago-Iwoye. A former Polytechnic Lecturer and Bank Executive. A former pioneer and only Province Chairman of Old Ikorodu Province. A Teacher and Pastor by call. Holds a Bachelors Degree in Christian Leadership from ULS,USA.",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: ImgB32,
    name: "REV FRANK IGHO ERHABOR ",
    title: "DEPUTY FINANCIAL SECRETARY",
    position: "SENIOR PASTOR",
    church: "Good Success Christian Center",
    address: "6, Tologbo Road Magbon Badagry",
    headOffice: "",
    tel: "08033324470",
    dOb: " 6th July",
    email: "",
    chapter: "",
    province: "Badagry",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "I'm born on 6th July 1962. Got born again 1976. CHURCH OF GOD. 1976-1986 FOUR-SQURE GOSPEL CHURCH 1986-1994 LIVING FAITH CHIRCH WINNERS CHAPEL 1995- Feb. 1999 SHEKINAH MINISTRY INT'L March 1999-Feb. 2001 Good Success Christian Centre April 2001-date. Ordained Into the ministry by BISHOP DAVID OYEDEPO March 1996. Served in living Faith from March 1996-December 1999. Moved to Shekinah ministry 2000 unser the leadership of LATE Apostle J M WILLIAMS. PFN JOURNEY: Area Coordinator, MAGBON. 2002-2004 Zonal Chairman MAGBON. 2005-March 2006 Regional Secretary. April 2006-June 2010 Province vice Chairman June 2010-2012 State Deputy Financial Secretary 2014-date. ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: tonyeImg,
    name: "Apostle Tonye Nengi Oliver ",
    position: "President",
    title: "PUBLICITY SECRETARY ",
    church: "Jesus Heals the Sick Ministries",
    address: "951, Admiral Oduwaiye St Omole Phase 2",
    headOffice: "",
    tel: "08933919496",
    dOb: " December 13 ",
    email: "tonyeoliver5@gmail.com pastortonye@jesushealsthesick.org",
    chapter: "",
    province: "Kosofe",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: 'Pastor Tonye Oliver is married to Pastor (Mrs ) Bike Oliver, together they oversee Jesus Heals the Sick Ministries, an apostolic ministry based in Lagos Nigeria. With a mandate to take the healing power of Jesus to this generation, he travels, holding crusades, healing schools, apostolic ministers conferences. He is a publisher and author of a number of publications notably the ministry flagship Publication the Power that Heals the Sick Magazine. He hosts the popular Healing Where Are You program on the Supernatural Online TV platform which can be watched on various platforms online. www.sotv.online/HEALING-WHERE-YOU-ARE www.trimurl.co/healingwhereyouare www.jesushealsthesick.org',
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "APOSTLE MIKE HONOUR AKINYOADE",
    title: "DIRECTOR , DIGITAL MEDIA",
    church: "The Royalty Place",
    address: "3, Church Street, Odo-Eran Ekore Bus-Stop, Oworoshoki, Lagos",
    headOffice: "",
    dOb: "22nd August",
    tel: "08038518769, 08153287478, 08107684912",
    email: "apostleakinyoade@gmail.com; theroyaltyplacetrp@gmail.com",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: helenImg,
    name: "Mrs Karika Helen Atebefia",
    title: "DIRECTOR, CHILDREN AND TEENS",
    position: "National Secretary, Children Evangelism Ministry",
    church: "Upper Room Baptist church",
    address: "2A Davies, by Oba nle Aro Bus stop, Ketu Lagos",
    headOffice: "",
    tel: "08023285245",
    email: "helenkarika@yahoo.com",
    dOb: "February 12",
    chapter: "",
    province: "Kosofe Province",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "I am Helen Atebefia Karika, a Christian and National Children Evangelism Ministry Secretary of Upper Room Baptist Church. I am a trained and commissioned children minister. I also hold a post graduate diploma in Theology of the Upper Room Baptist Church Bible college. Studied biochemistry at the University of Benin, where I obtained a B.Sc degree. I articled with audit and accounting firms and qualified as a chartered accountant. I am also a chartered Tax practitioner. I am currently a business woman dealing in cement. I am married with children. ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: ericImg,
    name: "Rev Dr Eric IGHALO",
    title: "STATE DIRECTOR- Community and People Development Directorate",
    church: "CHANGEMAKERS AFRICA YOUTH EMPOWERMENT INITIATIVE",
    address: "142 OBA AKRAN STREET IKEJA",
    headOffice: "",
    tel: "08023291520(main line) 09092218683",
    email: "changemakersafrica@gmail.com, ericandpartners@gmail.com",
    dOb: "19th Dec.",
    chapter: "",
    province: "IKEJA",
    associatePastor: "",
    position: "President",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "Dr Eric IGHALO is a Development Specialist, Financial Planner and committed Church leader with a passion for Youth and Community. Having served as National Supt. Of The Liberty Evangelical Church and National President of SCM of Nigeria, he is currently African Chair of the WSCF,Geneva, Chair of CRUDAN and an Elder at The Potters House of Lagos. Nigeria ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "PASTOR OLUMIDE OLUGBENLE",
    title: "Directorate of Learning & Development",
    church: "The Father's House, Rehoboth International Christian Centre",
    address: "62, Bamidele Eletu Avenue, Osapa-London, Lekki.",
    headOffice: "",
    tel: "08036586433",
    dOb: "3rd June",
    email: "olumideolugbenle@gmail.com",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "Bishop Dr. Theophilus Taiwo Ajose.",
    title: "Director, Politics and Governance",
    church: "Bishop Ajose Ministries International.",
    address:
      "17B Adekunbi Crescent Off Allen Avenue off Ladipo Kasumu street, Ikeja, Lagos.",
    headOffice: "",
    dOb: "May 31st",
    tel: "",
    email: "bishopajoseministries@gmail.com",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "PASTOR CHIGOZIE ANYANWU",
    title: "MUSIC DIRECTOR",
    church: "Zion The City of The Lord Ministries",
    address:
      "11, Eko Akete Close, Off St. Greggory's College Rd, Obalende, Lagos",
    headOffice: "",
    dOb: "24th February",
    tel: "07040909240, 07030505092",
    email: "chiggywise@yahoo.com",
    chapter: "Obalende",
    province: "Island/Eti-Osa",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: amusanImg,
    name: "Evangelist Olalekan Babatunde Amusan",
    title: " DIRECTOR EVANGELISM AND MISSIONS",
    positon: "Director of Missions and Network ",
    church: "Christ Image Assembly",
    address: "27 Carter Street Ebute Metta Lagos",
    headOffice: "",
    dOb: "August 3rd",
    tel: "08023468777",
    email: "lekanonmission@yahoo.com",
    chapter: "",
    province: "Ikeja",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "I'm an Evangelist by calling ,A theologian by training and a practicing Missionary . I am currently serving as Director of Evangelism &Missions in PFN Lagos .Also serving as Southwest Chairman of Nigeria Evangelical Missions Association.Lagos State coordinator of GO MOVEMENT. ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: obinnaImg,
    name: "Pastor Obinna Paul Egbuonu",
    title: "DIRECTOR PLANNING AND STRATEGY",
    position: "Lead pastor",
    church: "The Haven Rock Church",
    address: "28 Omotayo Banwo Street Ogudu Orioke Ogudu Lagos",
    headOffice: "",
    dOb: "8th January",
    tel: "09012652206",
    email: "obimsema75@gmail.com",
    chapter: "",
    province: "Ikeja Central",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "Born 8th January ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: ImgB8,
    name: "BISHOP MOSES ALABI IDRIS ",
    title: "STATE PRAYER DIRECTOR",
    position: "GENERAL OVERSEER",
    church: "LIVING ROCK GOSPEL CHURCH INT'L ",
    address: "1/3 TINUADE ADEPEGBA STREET OFF ADEPEGBA STREET ABULE EGBA LAGOS. ",
    headOffice: "",
    dOb: "10TH MARCH",
    tel: "08022232704; 0905 579 0517",
    email: "livingrockgospel@yahoo.com",
    chapter: "Abule Egba",
    province: "Ifako/Ijaiye",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "Lover of Jesus and kingdom work ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: osayiImg,
    name: "REV DAVID AUSTIN OSAYI ",
    title: "PROTOCOL DIRECTOR",
    position: "SENIOR PASTOR",
    church: "The Reigning Christian Church",
    address: "12 AJIMSON STREET, WATERSIDE, OJO LAGOS ",
    headOffice: "",
    dOb: "22nd May",
    tel: "07034533799, 08070652674",
    email: "davidosayiaustin@gmail.com",
    chapter: "",
    province: "AMUWO/ORIADE PROVINCE ",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "REV DR DAVID AUSTIN OSAYI, THE SENIOR PASTOR, THE REIGNING CHRISTIAN CHURCH, OJO LAGOS, THE PRESIDENT END TIME CHRISTIAN MISSION, LAGOS NIGERIA DIRECTOR OF PROTOCOL PFN LAGOS STATE, MARRIED TO PASTOR PATIENCE N.OSAYI AND BLESSED WITH CHILDREN ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "PASTOR (DR) OLUMUYIWA ADEDOYIN OLULANA",
    title: "DIRECTOR - INTERMINISTERIAL",
    church: "Glorious Destiny Church",
    address: "4,Cecilia Odutuga Street, Ilasamaja, Lagos",
    headOffice: "",
    dOb: "10th September",
    tel: "08033123225, 08120070680",
    email: "oluolulana54@gmail.com",
    chapter: "Ilasamaja",
    province: "Isolo/Mushin",
    associatePastor: "Pastor Mrs. M. O. Olulana",
    associatePastorTel: "08033185708",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: ImgB11,
    name: "REV. Oladele Olasunkanmi Afolabi ",
    title: "State Director, Project and Infrastructure ",
    position: "Senior Pastor",
    church: "Christ Base Assembly",
    address: "10 Shokoya Street Oworonsoki",
    headOffice: "",
    tel: "08034876621",
    email: "oladele.afolabi@ymail.com",
    dOb: "18th June",
    chapter: "Ilasamaja",
    province: "Kosofe",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "Senior and Lead Pastor of Christ Base Assembly. Our church has a mandate to raise leaders, win souls and impact our world ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: elebuteImg,
    name: "REV DR MOSES BABATUNDE ELEBUTE",
    title: "DIRECTOR - BUSINESS DEVELOPMENT ",
    position: "Founding & Presiding Pastor",
    church: "International Christian Faith Mission",
    address: "14, Aina Close, New Garage, Gbagada, Lagos.",
    headOffice: "",
    tel: "08023159130",
    email: "moseselebute58@gmail.com",
    dOb: "December 4th",
    chapter: "Ilasamaja",
    // province: "Kosofe",
    province: "Mainland Province",
    associatePastor: "Pastor (Mrs) Toyin Elebute",
    associatePastorTel: "08053077798",
    associatePastorChapter: "Gbagada",
    associatePastorProvince: "Mainland",
    bio: "Born to a Muslim family and became born again in 1976. Graduated with Master degree in Agricultural Economics from University of Ibadan. Obtained doctorate degree in Theology and PhD in Christian Education. Chapter President Eko chapter of Full Gospel Business Men Fellowship International, Lagos. Superintendent of Sunday school department in Foursquare Gospel Hdqt Church, Yaba, Lagos . In 1997, started a church called International Christian Faith Mission at Gbagada, Lagos. 8 year Mainland Province Chairman. Currently Director of Business Development Directorate at Lagos State PFN. President, Commonwealth Coop & Multipurpose Society to provide financial support for PFN members. I love Lord Jesus Christ.",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "BISHOP ANDY OGBU",
    title: "DIRECTOR, SECURITY AND INTELLIGENCE",
    church: "Dominion Life Centre",
    address:
      "18, Ebun Otti Street off Frank Lass Bus stop Opp. Lagos State University, Ojo, Badagry Exp.",
    headOffice: "",
    tel: "08033122772, 08120870163",
    email: "dominionlife@gmail.com",
    dOb: "23rd November",
    chapter: "Ojo",
    province: "Ojo",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: kenImg,
    name: "Kennedy Chukwukadibia Chidubem ",
    position: "Lead Pastor ",
    title: "YOUTH DIRECTOR",
    church: "World Lighters Ministries",
    address: "2 Ecwa church road by Alafia bus stop, Coker Orile Iganmu Lagos.",
    headOffice: "",
    tel: "07064691511",
    email: "Kengivethemlight@gmail.com",
    dOb: "18/12",
    chapter: "",
    province: "Surulere province ",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "I was born 18th December 1975, from Umuekele in Eziama, Ahiazu Mbaise local government of Imo state. I hold a diploma wkth Obfemi Awolowo University and a Dlctorate degree in thelogy from Restoration Bkble Institute Lagos. Am happily married and blessed wkth godly seeds. ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "Apostle Dr Ameh Joseph Sunday.",
    title: "DEPUTY WELFARE AND EMPOWERMENT OFFICER",
    church: "Restoration Royal Mega Church.",
    address:
      "15/16, Mohammed Adah Street, Gojere Town, Opposite Ojo Barracks. Ojo Lagos.",
    headOffice: "",
    tel: "08033000758. 08154146579.",
    email: "doctorameh@gmail.com",
    dOb: "",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: oladeleImg,
    name: "Rev Dr Oladele Rufus Ajayi ",
    title: "DEPUTY DIRECTOR INTERMINISTERIAL",
    position: "Chaplain",
    church: "Chapel of Christ The Light",
    address: "1, IPM ROAD ALAUSA IKEJA ",
    headOffice: "",
    tel: "08023269835",
    email: "oladeleajayi83@gmail.com",
    dOb: "13th January",
    chapter: "",
    province: "Ikeja",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "Rev'd Dr Oladele Ajayi is an ordained and a full time minister of gospel with over 25years experience. A highly motivated and goal oriented individual with diverse experience in church and people management. A thespian turned Preacher. He is an alumnus of LIFE Theological Seminary, Ikorodu, Lagos. He holds D.Th, B.Th, M.Th, DMIN (Guidance and Counseling), D.D and PhD (Church Management). He has attended various leadership course like Word of Life Bible Institute (WOFBI), Daystar Leadership Academy, Church and Society, Peace and Conflict Resolution, University of Leadership and Sound Doctrine, Institute of National Transformation. An erudite scholar, lecturer, a prolific writer and Conference Speaker who has authored both theological and inspirational books. He is an experienced leader, church consultant and mentor in Pastoral ministry and leadership development. He is currently a state duputy director Interministerial of the Pentecostal Fellowship of Nigeria, Lagos State. He is the Executive Director of LivingRight Initiative, a moral and character development NGO targeted at Youths moral transformation. He is the chaplain of Chapel of Christ the Light Alausa, Ikeja. A Lagos state government established church. Dr Dele and his wife Pastor Mrs Folashade Ajayi with their children served the Lord together and live in Lagos.",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: clemsImg,
    name: "APOSTLE CLEMS PREACHER AKIBOR",
    title: "DEPUTY DIRECTOR, STRATEGY AND PLANNING ",
    position: "General Overseer",
    church: "Jubilee Family Christian Church int'l.",
    address: "40/41 Sule Descendants way, off Church bustop, Okoko Lagos.",
    headOffice: "",
    tel: "08034028313",
    email: "clemspreacher@gmail.com",
    dOb: "February 12th",
    chapter: "",
    province: "OJO",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "I'm the current Deputy strategy and planning director of Lagos State PFN",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "Bishop Louis E. Henshaw",
    title: "Deputy Director of Projects and Infrastructure",
    church: "Sure Covenant Assembly",
    address: "Meta-life center 32 Apena street Ago, Okota, Lagos",
    headOffice: "",
    tel: " 08033000772",
    email: "apostlelouis12@gmail.com",
    dOb: "8th March",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: joyImg,
    name: "BISHOP OBERIKO JOY",
    title: "DEPUTY DIRECTOR, DIGITAL MEDIA",
    position: "G.O",
    church:
      "DIVINE SOULS FOR CHRIST CHURCH INTERNATIONAL ",
    address: "No 3 luqmon avenue Ahmadiya ",
    headOffice: "",
    tel: "08023428133",
    email: "bishopjoyoberiko2012@gmail.com",
    dOb: "DECEMBER 25TH",
    chapter: "Ojokoro",
    province: "Ifako/Ijaiye",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "I AM BISHOP JOY OBERIKO,G.O OF DIVINE SOULS FOR CHRIST CHURCH INTERNATIONAL ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img25,
    name: "Pastor Emmanuel Oluwemimo Falodun",
    title: "Deputy Director - Directorate of Learning and Development ",
    position: "General Overseer",
    church: "Christ Salvation Victory Mission Int'l",
    address: "63, Falohun Street, Orile Iganmu, Lagos",
    headOffice: "",
    tel: "08033337793",
    email: "powerforexploits@gmail.com",
    dOb: "May 17",
    chapter: "",
    province: "Orile Iganmu, Coke-Aguda LCDA, Lagos State",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "Emmanuel Olu Falodun is one of Africa’s best leadership development coaches. Highly devoted, disciplined and dedicated to training and building leaders for maximum productivity. He is consumed with the passion to see people fulfill their God given mandate. He travels in Africa, Europe, America encouraging excellence and godliness. The vision for the top achievers network was birthed after years of working with young people across many fronts in 2010. Through various mediums, lives are being positively affected. He is the General Overseer of CHRIST SALVATION VICTORY MISSION INT’L. He founded the Victory Leadership Institute where leaders are being sharpened for excellence. He presides over the Power For Exploits Ministries which organizes Conferences, Seminar and Summits for empowerment since 1997. A graduate of University of Lagos, with B.Sc. Business Administration. He has a honorary Doctorate degree on Leadership & Christian ministry from Global Christian University, Nigeria This anointed vessel has authored over thirty books on Leadership, Prayer, Youth Empowerment and Church Ministry. He coordinates the Nations Prayer And Fasting Network (NAPFAN) a global prayer network of Christian leaders and the million-plus intercessors (millionplusintercessors.org) Through the Faith Heroes Africa Project (www.faithheroesafrica.org) He supervises the documentation of the moves of God in Africa. He's been married for over twenty three years to Pastor Ruth Oluyemi an intercessor and partner in ministry. ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: idiongImg,
    name: "APOSTLE Idongesit Ezekiel James ",
    title: "DEPUTY DIRECTOR, SECURITY AND INTELLIGENCE",
    position: "General overseer ",
    church: "ALIVE TABERNACLE INT'L",
    address:
      "9 Irepodun Street off Arigbanla Road behind NYSC CAMP IYANA IPAJA ",
    headOffice: "",
    tel: "08054449444/08033393359",
    email: "",
    dOb: "",
    chapter: "",
    province: "Agege/Ogba province ",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "I am Apostle Idongesit Ezekiel James,the General overseer of Alive Tabernacle Int'l Also the state Deputy director of Security and intelligence, lagos state pentecostal fellowship of Nigeria ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "REV. DR. AKINLADE",
    title: "DIRECTOR, HEALTH AND MEDICAL SERVICES",
    church: "",
    address: "",
    headOffice: "",
    tel: "+234 909 170 3599",
    email: "",
    dOb: "",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "Apostle Stephen Atolani Pius",
    title: "DEPUTY DIRECTOR, PRAYERS",
    church: "FIRST ESTATE WORLD OUTREACH MINISTRY INC.",
    address:
      "EDEN CHAPEL LAOGS, KM 5 LASU - Isheri Expressway College Bus Stop, Mojeed Sanni Street Igando Lagos.",
    headOffice: "",
    tel: "08033333854; 09096965179",
    email: "pastsong@yahoo.com, edenchapel42@gmail.com",
    dOb: "15th Jan",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "Barr. (Mrs.) Tina Oganiru",
    title: "Deputy Director, Legal ",
    church: "CPM Okota Branch",
    address: "",
    headOffice: "",
    tel: "0802 315 1022",
    email: "a_oganirulaw@yahoo.com",
    dOb: "3rd August",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: davidImg,
    name: "Pastor David Oluwatosin Oladipupo",
    title: "DEPUTY PROTOCOL DIRECTOR",
    position: "Overseer",
    church: "CommunionHouse",
    address: "House 11 k close 34 road Gowon Estate Egbeda ",
    headOffice: "",
    tel: "08100675902",
    email: "tsn_oladipupo@yahoo.com",
    dOb: "02 August",
    chapter: "",
    province: "Alimosho",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "Date of birth: 2 August 1970 Lead pastor CommunionHouse Married With a daughter ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: benImg,
    name: "Apostle Dr Benjamin Omoregie Osagie",
    position: "General Overseer",
    title: "DEPUTY DIRECTOR, EVANGELISM AND MISSIONS",
    church: "Rehoboth Church of Christ Ambassadors Worldwide",
    address:
      "5, Rehoboth Street, Off Baba Adisa Road, Ibeju Lekki, Lagos State",
    headOffice: "",
    dOb: "16/03/1966",
    tel: "08023081292",
    email: "apostlebenomoregie@gmail.com",
    chapter: "",
    province: "Ibeju Lekki",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "Apostle Dr Ben Omoregie is apostle of faith. I have been in church ministry from 1990. Rector, Christ Ambassadors Theological Academy and a Missionary. ",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: joshuaImg,
    name: "PASTOR DR. Barnabas Aihebho-oria Otoibhi ",
    title: "DEPUTY DIRECTOR, POLITICS AND GOVERNANCE",
    position: "General Overseer",
    church: "Temple Rebuilders Ministry.",
    address:
      "5, Aimasiko Street, Off Afariogun Street, By Charity Bus-Stop, Oshodi, Lagos",
    headOffice: "",
    tel: "08033136167, 08125720158",
    email: "barnytessy@yahoo.com",
    dOb: "15/12",
    chapter: "Oshodi",
    province: "Mushin/Oshodi",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    bio: "PASTOR DR. BARNABAS AIHEBHO-ORIA OTOIBHI , was born to a heathen family on Dec. 15, 1958 . He got born again on June 23 , 1987 . He was a member of Nigerian Institute of Quantity Surveyors and Nigerian Institute of Building , before he received a Divine call into the preaching ministry with a Divine mandate to Rebuild the House (Temple) of God, on October 24 , 1994 and another mandate to be God's Spokesman on issues of governance , in January. 2009. He has served as Spokesman , Christian Association of Nigeria , Oshodi Local Government Chapter , Lagos . He has also served and Pentecostal Fellowship of Nigeria in various capacities as : Chapter Chairman, Oshodi ( 2007 to 2010) ; Province Deputy Chairman / Acting Province Chairman , Mushin Oshodi Isolo ( 2010 to 2012) ; Lagos State Deputy Director , Church Registration and Computerization (2012 to 2014) ; Lagos State Deputy Director, Inter Governmental Relations (2014 to 2018) ; Lagos State Deputy Director , Directorate of Politics and Governance (2018 to date ). He has also served in other organizations which are : Chairman, Parents Teachers Association, Rising Sun Group of Schools, Lagos State ( 1999 to 2010) ; Chairman , Parents Forum / Lagos State Schools Management Committee , Oshodi Comprehensive Senior High School, ( 2007 to 2015). He is a distinguished recipient of the Development In Nigeria Merit Award, by PSR Magazine, at Abuja, Nigeria. Pastor Dr. Barnabas Aihebho-oria Otoibhi is the National General Secretary, United Christian Ministerial Association (USA), Nigeria Chapter. He is the author of books such as 'Help For Cultists' , 'Memorandum of Understanding On Noise Pollution Control in Lagos State', 'Tonic For Relationships' and several articles. He is an Ambassador to Lagos State Government in Nigeria. He is happily married to Evangelist Tessy Otoibhi and they are blessed with godly children.",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "Rev. Ngozi Nwaogu",
    title: "EX-OFFICIO (CPM)",
    church: "",
    address: "",
    headOffice: "",
    tel: " +234 803 713 4505",
    dOb: "",
    email: "",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "Rev. Mrs Roseline Odiete ",
    title: "WELFARE AND EMPOWERMENT OFFICER",
    church: "Christ Peculiar Church Int",
    address: "33b Ibafon street behind Zenith Bank  Oshodi Apapa Express way",
    headOffice: "",
    tel: "+234 803 064 5565",
    dOb: " January ",
    email: "odieteroseline43@gmail.com",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: akpabioImg,
    name: "REV (MRS) GLORY AKPABIO ",
    title: "CO-ORDINATOR WOMEN WING",
    church: "Christ Chapel International Church, Surulere",
    address: "",
    headOffice: "",
    tel: "08032234656",
    dOb: "  23rd February ",
    email: "glakia@yahoo.com ",
    chapter: "Kilo/Ikate",
    province: "Apapa/Surulere",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "PASTOR CLEM IWEGBU ",
    title: "EX-OFFICIO (TREM)",
    church: "The Redeemed Evangelical Mission",
    address: "TREM NATIONAL HQTRS/OKOTA ISOLO",
    headOffice: "",
    tel: "08023152389",
    dOb: "  30th August ",
    email: " rev.clemiwegbu@yahoo.com  ",
    position: "",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "BARRISTER TOMI VINCENT ",
    title: "LEGAL ADVISER",
    church: "Ivory Solicitors",
    address: "374, Ikorodu Road, Maryland. Lagos ",
    headOffice: "",
    tel: "08033026306, 08037122564",
    dOb: "  18th May",
    email: "  tomivincent@yahoo.com   ",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "Enushai Sylvester Success Ngozi  ",
    title: "EX-OFFICIO (MFM) ",
    church: "Mountain of fire and miracles ministries",
    address: "13 Olasimbo street  Yaba Lagos ",
    headOffice: "",
    tel: "08037116342",
    dOb: "April 16",
    email: "  enushaisylvester1234@gmail.com   ",
    chapter: "Iwaya chapter",
    province: "Mainland province",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "BISHOP MATTHEW EGWUOWA  ",
    title: "EX OFFICIO, CGMI",
    church: "Church of God Mission ",
    address: "N0. 10, Oludegu avenue, Mafoluku,off Airport road ",
    headOffice: "",
    tel: "+234 803 327 4311 ",
    dOb: " 01/10 ",
    email: "  egwowamatthew@gmail.com   ",
    chapter: "waya chapter",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "Pastor S. A. Lebi ",
    title: "Ex Officio, Lord's Chosen",
    church: " ",
    address: " ",
    headOffice: "",
    tel: "+234 803 395 0888 ",
    dOb: " ",
    email: " ",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "Evang. Chris Joseph",
    title: "Ex-officio, Lord's Chosen  ",
    church: "The Lord's Chosen Charismatic Revival Movement",
    address:
      "10 Odofin Park Estate, Oshodi Apapa Express Way by Ijesha Bus-stop, Lagos",
    headOffice: "",
    tel: "08060231959, 07069426060",
    dOb: "28/10 ",
    email: "chris.mlpwo@gmail.com",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: boseImg,
    name: "BOSE OLASUPO ",
    title: "STATE ADMIN SEC.",
    church: "Guiding Light Assembly",
    address: "Layi Ajayi-Bembe Rd, Parkview Estate, Ikoyi ",
    headOffice: "",
    tel: " 08034961055",
    dOb: " 5th April ",
    email: " Ennywealt4real@yahoo.com ",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "BISHOP MATTHEW EGWUOWA",
    title: "EX OFFICIO, CGMI ",
    church: "Church of God Mission",
    address: "N0. 10, Oludegu avenue, Mafoluku,off Airport road.",
    headOffice: "",
    tel: "+234 803 327 4311",
    email: "egwowamatthew@gmail.com",
    dOb: "01/10",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
  {
    id: uuidv4(),
    img: Img,
    name: "Bishop Samuel Oniekoro",
    title: "Ex-officio, Gospel Church of Christ Rep.",
    church: "Gospel Church of Christ.",
    address:
      "40 Dada Onijama crescent  BAba Sule Awodio-ora  Estate Agegunle Apapa",
    headOffice: "",
    tel: "+234 814 570 3985",
    email: "bishopsamuelethoja@gmail.com",
    dOb: "",
    chapter: "",
    province: "",
    associatePastor: "",
    associatePastorTel: "",
    associatePastorChapter: "",
    associatePastorProvince: "",
    header: "State Director"
  },
];

//The Chairmen
export const chairmen = [
    {
      id: uuidv4(),
        img: Img,
        name: "Apostle Dr James Olulaja Iyanda",
        title: "CHAIRMAN - ALIMOSHO PROVINCE",
        church: "GoYe Pentecostal Mission International",
        address: "55 alimoso road pako bus stop LG Lagos",
        headOffice: "",
        tel: "08060582967, 08023407924",
        email: "olulajaiyanda2016@gmail.com",
        dOb: "June 3rd",
        chapter: "",
        province: "",
        associatePastor: "Rev. Mrs. Temi -James Iyanda",
        associatePastorTel: "07064680302",
        associatePastorChapter: "",
        associatePastorProvince: "",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: ephriamImg,
        name: "REV Ephraim Nnaemeka Ifionu",
        position: "Senior Pastor",
        title: "CHAIRMAN - SURULERE PROVINCE",
        church: "Glory Restoration Ministries International",
        address: "30 Coker Road Orile Iganmu, Lagos.",
        headOffice: "",
        tel: "08033331066, 08097362066",
        email: "ephraimifionu@gmail.com",
        dOb: "13th December",
        chapter: "",
        province: "Surulere",
        associatePastor: "Rev. Mrs. Temi -James Iyanda",
        associatePastorTel: "07064680302",
        associatePastorChapter: "",
        associatePastorProvince: "",
        bio: "I am Rev Ephraim Nnaemeka Ifionu the Senior Pastor of Glory Restoration Ministries Int'l and the provincial chairman man of PFN Surulere Province.",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: Img,
        name: "APOSTLE NELSON CHUKWUDI EKE OKPARA",
        title: "CHAIRMAN, APAPA PROVINCE",
        church: "Sowers' International Ministry",
        address: "6 Akaka Lane, off DadaOnijomoh Crescent, behinds Berger yard, Otto Wharf, Ajegunle, Apapa.",
        headOffice: "",
        tel: "09054562000",
        email: "bishopinsowers@gmail.com",
        dOb: "07 September.",
        chapter: "",
        province: "",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: Img,
        name: "Rev Adeleke Mike Aderinto",
        title: "CHAIRMAN, OJO PROVINCE",
        church: "Life Transforming Evangelical Church.",
        address: "34 Kareem Street Shibiri",
        headOffice: "",
        tel: "08023588405",
        email: "mikeadeleke77@gmail.com",
        dOb: "16/12",
        chapter: "",
        province: "",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: tonyImg,
        name: "Rev. Tony Emuoruona Samson",
        title: "CHAIRMAN, LAGOS ISLAND PROVINCE",
        position: "Zonal Pastor",
        church: "TREM (The Redeemed Evangelical Mission )",
        address: "20 Remi Olowude way Off Marwa Bus Stop Lekki, Lagos",
        headOffice: "",
        tel: "+2348033562990",
        email: "tonysamson135@gmail.com",
        dOb: "02/09",
        chapter: "",
        province: "Lagos Island Province",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        header: "Province Chairman",
        bio: "Tony Samson is the Senior Pastor of TREM, Victoria Island, Lagos. I am married to Pastor Mrs Pat Samson"
    },
    {
      id: uuidv4(),
        img: ImgC6,
        name: "Bishop John Oyedeji Seton Benhotons",
        title: "CHAIRMAN, BADAGRY PROVINCE ",
        positon: "FOUNDER",
        church: "DIDASKO GLOBAL MISSION/REIGNING WORD FOUNTAIN CHURCH",
        address: "ASSOCIATION AVENUE,IBEREKO, BADAGRY",
        headOffice: "",
        tel: "08034951550",
        email: "johnbenhotons@yahoo.co.uk",
        dOb: "26th September",
        chapter: "",
        province: "BADAGRY",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        bio: "PROFILE OF DR J.O.S BENHOTONS Dr John Oyedeji Benhotons is the CEO of DIDASKO global mission. Didasko mandate is Enriching leadership and perfecting the saints. He got called into ministry 5th April 1987 under the ministry of Rev. Dr Tunde Joda of the Christ Chapel internatioal Churches. He holds an Advanced Diploma in Accounting/Finance and Bachelor’s degree in Business Administration. He also hold a PhD in Christian Ministry/Theology. He is also the president of the WORD IMPACT NETWORK a ministry based in Ghana with a national office in Nigeria. He runs leadership training program in different major towns across the WEST COAST of Africa. He is also the founder and president of the INTERNATIONAL NETWORK OF COVENANT MINISTERS. (INOCOM) and runs a monthly interactive ministers forum known as APOSTOLIC MENTORING. Founder of the IMPACT CHRISTIAN LEADERSHIP ACADEMY. An academy that has been graduating students from year 2001 Married to Rev. Dr Shallom Folashade, Their marriage is blessed with five wonderful children and grandchildren.",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: babatopeImg,
        name: "Pastor Babatope Rufus ILESANMI ",
        position: "Senior Pastor ",
        title: "CHAIRMAN - IKEJA PROVINCE",
        church: "Revival Summit Ministries",
        address: "Plot 165, Obadina street, Omole Phase 1, Ikeja L/G.",
        headOffice: "",
        tel: "08032139688, 08023007209- WhatsApp line",
        email: "pastortopesanmi@gmail.com",
        dOb: "23rd December.",
        chapter: "",
        province: "Ikeja Province",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        bio: " 'Tope Ilesanmi has a proven apostolic calling that involves sound teaching, family deliverance, community deliverance, and open-air crusades. He is a disciple of Rev. Joe Olaiya, the President and founder of Living Faith Foundation. An alumnus of Lagos State Polytechnic, he is also an associate member of Chartered Institute of Bankers, and of the Institute of Marketers. A Graduate several Leadership Institutes. He is currently running an Advance Diploma Course at School Of Politics, Policies and Governance under the leadership of Dr Oby Ezekwesili, former Minister of Education. He is a recipient of the Apostle Wole Oladiyun CLAM/PFN Award for Excellence in Evangelism/Outreach. Presently, he serves as the Chairman, Pentecostal Fellowship of Nigeria, Ikeja Province. He is a co-pioneer of Ido-Ekiti For Christ Ministry, and the founder of Revival Summit Ministries. He has authored 3 books till date. He is married to Tola Ilesanmi and they are blessed with children.",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: peterImg,
        name: "Commander Peter O. Olabode",
        position:"President ",
        title: "CHAIRMAN - IKORODU PROVINCE ",
        church: "Moment of Grace and Fire Ministry",
        address: "2, Salvation and Praise City Close Keke Maruwa Last Bus Stop Selewu, Igbogbo Lagos",
        headOffice: "",
        tel: "08032288773",
        email: "peterolabode8@gmail.co",
        dOb: "8/9",
        chapter: "",
        province: "Ikorodu",
        associatePastor: "Pastor Mrs. V. O. Olabode",
        associatePastorTel: "08032288773",
        associatePastorChapter: "",
        associatePastorProvince: "",
        bio: "Chairman, PFN Ikorodu Province",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: femiImg,
        name: "BISHOP FEMI EMMANUEL SONEYE",
        title: "CHAIRMAN - ETIOSA PROVINCE",
        position: "SENIOR PASTOR",
        church: "MIRACLEBREED ASSEMBLY",
        address: "TRAILBLAZERS CONVENTION CENTER TREASURE ESTATE AFA BUS STOP, SHANGOTEDO KM 46, LEKKI-EPE EXPRESS WAY. LAGOS",
        headOffice: "",
        tel: "08023039870",
        email: "miraclebreed_assembly@yahoo.com, femi.soneye5570@gmail.com",
        dOb: "May 5th",
        chapter: "",
        province: "ETIOSA PROVINCE",
        associatePastor: "Pastor Mrs. V. O. Olabode",
        associatePastorTel: "08032288773",
        associatePastorChapter: "",
        associatePastorProvince: "",
        bio: "Bishop Femi Soneye holds an MBA in Marketing, a fellow Direct institute of Marketing, member institute of management. Chancellor Glowing light theological college, Married to Rev Dr Abidemi Soneye and blessed with two beautiful children.",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: patrickImg,
        name: "Apostle Dr. Patrick Utulu",
        title: "CHAIRMAN - AMUWO ODOFIN/ORIADE PROVINCE",
        position: "General overseer",
        church: "Dominion Gate Ministrie International inc.",
        address: "No 1 church street, Zone 2, liverpool Estate, satellite, Lagos. ",
        headOffice: "",
        tel: "08033215129",
        email: "apostleUtulu@yahoo.com",
        dOb: "20th August",
        chapter: "Satellite Chapter",
        province: "Amuwo-Odofin/Oriade",
        associatePastor: "Pastor Mrs. V. O. Olabode",
        associatePastorTel: "08032288773",
        associatePastorChapter: "",
        associatePastorProvince: "",
        bio: "Missionary Apostle, Trainer, Consultantant and humanitarian",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: Img,
        name: "APOSTLE M. A. OLUGBARO",
        title: "CHAIRMAN, ITAMAGA PROVINCE",
        church: "",
        address: "",
        headOffice: "",
        tel: "08033834251",
        email: "",
        dOb: "",
        chapter: "",
        province: "Itamaga Province",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: oliverImg,
        name: "GENERAL OLIVER PRAISE",
        title: "CHAIRMAN, AGEGE PROVINCE ",
        position: "Fresh Fire Revival Chapel ",
        church: "FRESH FIRE REVIVAL CHAPEL",
        address: "KLM 287 Lagos Abeokuta express way Pleasure B/s Lagos ",
        headOffice: "",
        tel: "07061121896, 08023175028",
        email: "bishoppraiseoliver@gmail.com",
        dOb: "May 12",
        chapter: "",
        province: "Agege Ogba Province ",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        bio: "Bishop Dr Praise Oliver Akhanamoya is a native of Afashio Uzairue, Etsako west LGA of Edo State. Ordained into the ministry in May 1988. A Graduate of Electrical Electronics Engineering (UNIPORT). A Graduate of All nation bible school And was commissioned by the late ArchBishop Benson Idahosa in 1990. A two times Doctorate degree holder in Theology with several other certificates and awards. A Prophet by calling with strong teaching Grace. Traveled extensively propagating the Gospel of Jesus Christ. Gifted with strong Deliverance Ministry. Lover of Jesus Christ. Former Chapter Chapter of PFN Agege South West. Now the Province chairman of PFN Agege Ogba Province. ",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: olaoyeImg,
        name: "Apostle JOSHUA OLUWAGBEMIGA OLAOYE",
        position: "GENERAL OVERSEER",
        title: "CHAIRMAN, KOSOFE PROVINCE",
        church: "TRIUMPHANT FAITH GLOBAL CHURCHES INC.",
        address: "6, OLAKUNLE STR., OWODE-AJEGUNLE, IKORODU ROAD, LAGOS.",
        headOffice: "",
        tel: "08086893567; 07055454378",
        email: "joshuatfm@yahoo.com",
        dOb: "23RD SEPT.",
        chapter: "",
        province: "KOSOFE",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        bio: "AN APOSTLE OF OUR LORD JESUS CHRIST,SENT TO THE NATIONS, TO LIBERATE NATIONS & DESTINIES.",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: samuelImg,
        name: "Pastor (Barr.) Samuel Olanrewaju Afilaka",
        title: "CHAIRMAN, AGBADO OKE ODO PROVINCE",
        position: "General Overseer",
        church: "The Faithful Reconcilers' Bible Ministry",
        address: "No. 14, Ifelodun Street, Agbelekale, Abule-Egba, Lagos State",
        headOffice: "",
        tel: "08023023038",
        email: "samafilaka@yahoo.com",
        dOb: "5th December.",
        chapter: "Agbelekale",
        province: "Agbado/Oke-Odo.",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        bio: "Born on 5th December, 1964 at Ise-Ijesa via Ilesa in Atakumosa East Local Government Area of Osun State, Nigeria. Got born again on 13th August, 1986. Called into Ministry on 26th February, 1993. Tutored in the christian way of life under the leadership of his biological father- Late Prophet Michael Afilaka in Christ Apostolic Church and his spiritual father - Late Rev. David Idowu Obembe of Christian Faith World Outreach Center (Overcomers' Church). Ilesa. He is the set man at The Reconcilers' Bible Ministry, Agbelekale, Abule-Egba, Lagos State founded in October, 2000. ",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: tundeImg,
        name: "Rev Alfred Olatunde Shoyemi",
        title: "CHAIRMAN, IFAKO IJAIYE PROVINCE",
        position: "General Overseer",
        church: "The Truth Christian Mission Inc.",
        address: "3/5 Adesola Street Off Sanni Balogun St. Abule-Egba Lagos.",
        headOffice: "",
        tel: "+234 802 283 9320",
        email: "olatundeshoyemi@yahoo.com",
        dOb: "December 3",
        chapter: "",
        province: "Ifako-Ijaiye",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        bio: "Holds Higher Diploma in Accounting and other Professional Certificates from ICAN and Institute Of Administrative Accountants. Formerly Management Accountant with AG Leventis Nigeria. Married to Olabisi Shoyemi with children and grandchildren. ",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: oladitiImg,
        name: "BISHOP SAMUEL OLUDARE OLADITI",
        position: "General Overseer",
        title: "CHAIRMAN, AYOBO/OKUNOLA PROVINCE",
        church: "VICTORY INTERNATIONAL FAITH MISSION",
        address: "11/13 VICTORY CLOSE OFF CANDOS ROAD BARUWA IPAJA LAGOS",
        headOffice: "",
        tel: "08023219541",
        email: "vifmlagos@yahoo.com",
        dOb: "MARCH 10TH",
        chapter: "",
        province: "Ayobo Okunola Province",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        bio: "Samuel Oludare Oladiti General Overseer of victory international faith mission Chairman of Ayobo/Okunola Province Born march 10 1967 Married and blessed with 4 kids ",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: ImgB17,
        name: "REV Sheriff Tosin Israel",
        title: "Chairman, Mainland Province",
        position: "Senior Pastor",
        church: "CITY OF GOD RESURRECTION ASSEMBLY",
        address: "4A ADARAMAJA ST, IWAYA YABA LAGOS",
        headOffice: "",
        tel: "08179936106",
        email: "israeltosin01@gmail.com",
        dOb: "OCTOBER 20TH",
        chapter: "",
        province: "Mainland",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        bio: "Sheriif Tosin Israel was Vice Chairman, Iwaya/onike/makoko, Chairman, Iwaya/Onike/makoko, Vice Chairman, Mainland Province, currently. He was born 20th October, 1966, to a Muslim family. He got converted in 1979 and entered the ministry on 6th December, 1985. He was a missionary for 14 years to the middle belt and south west of Nigeria. He was the leader of Overcomes Crusade Team International, a youth forum in Lagos State. He founded a church in 1988 named the City of God Ressurection Assembly and joined PFN in 2002. He is currently the Chairman, Mainland province, Lagos State. He is happily married with four daughters.",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: ImgC18,
        name: "Rev Aghraghra Victor Uzezi",
        title: 'CHAIRMAN, EPE PROVINCE ',
        church: "The Transformed Christian Assembly (Inc).",
        address: "54,kasali Oluwo St, Papa Epe Lagos State.",
        headOffice: "",
        tel: "08055805027; 08062492108",
        email: "vuaghraghra@gmail.com",
        dOb: "27/09/55",
        chapter: "",
        province: "Epe Province",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        positon: "General Overseer",
        bio: "My name is Reverend Victor Uzezi Aghraghra, General Overseer of The Transformed Christian Assembly Incorporated, and the PFN Chairman, Epe Province. ",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: Img,
        name: "APOSTLE GEORGE ANSELEM",
        title: "CHAIRMAN, ISOLO EJIGBO PROVINCE",
        church: "GRACE LIFE ASSEMBLY",
        address: "14 APATA STREET, OPPOSITE OBA PALACE IYANA EJIGBO. LAGOS STATE", 
        headOffice: "",
        tel: "08024719223, 08038891465",
        email: "bishopgeorge_a@yahoo.com Georgeanselem@gmail.com",
        dOb: "27/09/55",
        chapter: "",
        province: "",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        header: "Province Chairman"
    },
    {
      id: uuidv4(),
        img: Img,
        name: "APOSTLE OKECHUKWU ETIAKA.",
        title: "CHAIRMAN, MUSHIN OSHODI ",
        church: "CORNERSTONE BIBLE BELIEVERS CHURCH INT'L",
        address: "No 18a SADIKU STREET OGUNDELE MAFOLUKU LAGOS.", 
        headOffice: "",
        tel: "07039004545 / 09071111177",
        email: "okechukwuetiaka4christ@gmail.com",
        dOb: "MARCH 3rd",
        chapter: "",
        province: "",
        associatePastor: "",
        associatePastorTel: "",
        associatePastorChapter: "",
        associatePastorProvince: "",
        header: "Province Chairman"
    },
    
    {
      id: uuidv4(),
      img: Img,
      name: "REV DR ANDREW ISIAKA OLADEPO",
      title: "CHAIRMAN - IBEJU/LEKKI PROVINCE ",
      church: "SCRIPTURE LIFE CHRISTIAN CENTRE INC",
      address: "Plots 1/3 Scripture Life Way, Iya Alagbo Area Awoyaya. Ibeju Lekki Lagos.",
      headOffice: "",
      tel: "08023154453, 07043421533",
      email: "andrewoladepo4scripturelife@yahoo.com",
      dOb: "17th Oct.",
      chapter: "",
      province: "",
      associatePastor: "",
      associatePastorTel: "",
      associatePastorChapter: "",
      associatePastorProvince: "",
      header: "Province Chairman"
  },
]